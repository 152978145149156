<template>
  <div id="app">
    <nav-menu v-if="isLogging"></nav-menu>
    <router-view></router-view>
    <el-footer
      style="
        padding-top: 20px;
        text-align: center;
        color: white;
        font-size: 16px;
        background-color: #008f39;
      "
      >Copyright © 2020-2023 EcoWastes</el-footer
    >
  </div>
</template>
<script>
import NavMenu from "@/components/navMenu";
import Cookies from "js-cookie";
import notification from "@/mixins/notification";

export default {
  components: { NavMenu },
  mixins: [notification],
  data() {
    return {
      isLogging: true,
    };
  },

  async mounted() {
    if (Cookies.get("eco-token") !== undefined) {
      await this.$store.dispatch(
        "getUserInfoByToken",
        Cookies.get("eco-token")
      ).catch(this.$store.dispatch(
        "getUserInfoByToken2",
        Cookies.get("eco-token")
      ));
      if (Cookies.get("role") !== undefined) {
        if (Cookies.get("role") !== "ADMIN") {
          await this.$store
            .dispatch("checkLicenseValidityDate")
            .then(async (response) => {
              await this.$store.dispatch("setLicenseMessage", response.data);
              for (let validityDate of response.data) {
                await this.message(validityDate.name, "error", true);
              }
            });
          await this.$store
            .dispatch("checkPermissionValidityDate")
            .then(async (response) => {
              await this.$store.dispatch("setPermissionMessage", response.data);
              for (let validityDate of response.data) {
                await this.message(validityDate.name, "error", true);
              }
            });
          await this.$store
            .dispatch("checkReg")
            .then(async (response) => {
              await this.$store.dispatch("setRegMessage", response.data);
              for (let reg of response.data) {
                await this.message(reg.name, "success", true);
              }
            });
            await this.$store
            .dispatch("check1LotsBid")
            .then(async (response) => {
              await this.$store.dispatch("setCheck1LotsBid", response.data);
              for (let reg of response.data) {
                await this.message(reg.name, "success", true);
              }
            });
            await this.$store
            .dispatch("check2LotsBid")
            .then(async (response) => {
              await this.$store.dispatch("setCheck2LotsBid", response.data);
              for (let reg of response.data) {
                await this.message(reg.name, "success", true);
              }
            });
            
          await this.$store
            .dispatch("checkStoreValidityDate")
            .then(async (response) => {
              let array1 = [];
              array1=response.data;
              await this.$store.dispatch("checkStoreValidityDatehran").then(async (response) => {
                array1=array1.concat(response.data);
                await this.$store.dispatch("checkStoreValidityDatezahran").then(async (response) => {
                  array1=array1.concat(response.data);
                  await this.$store.dispatch("setStoreMessage", array1);
                  for (let validityDate of array1) {
                    await this.message(validityDate.name, "error", true);
                  }
                });
              });
            });
        }
      }
    }
  },
};
</script>
<style>
* {
  word-wrap: normal;
  overflow-wrap: normal;
  word-break: normal !important;
}

.searchPole {
  width: 100%;
}

.search {
  border-radius: 5px;
  background-color: #ecf5c7;
  padding: 10px;
}

body {
  margin: 0;
}

.el-menu-item {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ecf5c7;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.el-scrollbar {
  max-width: 70vw !important;
}
</style>
