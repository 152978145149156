import { AXIOS } from "@/AXIOS/http-common";

const state = {
  listAllHistory: [],
};

const urlPrefix = "waste-history";

const actions = {
  async getListAllHistory(context, data) {
    return await AXIOS.get(
      urlPrefix + "/list?page=" + data.page + "&size=" + data.size
    ).then((response) => {
      context.commit("setListAllHistory", response.data);
    });
  },

  async searchHistory(context, data) {
    return await AXIOS.post(
      urlPrefix + "/search?page=" + data.page + "&size=" + data.size,
      data.body
    ).then((response) => {
      context.commit("setListAllHistory", response.data);
    });
  },

  async deleteWasteHistory(context, data) {
    return await AXIOS.put(urlPrefix + "/delete", data);
  },

  async updateWasteHistory(context, data) {
    return await AXIOS.put(urlPrefix + "/update", data);
  },
};

const mutations = {
  setListAllHistory(state, data) {
    state.listAllHistory = data;
  },
};

const getters = {
  GETLISTALLHISTORY: (state) => state.listAllHistory,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
