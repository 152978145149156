const state = {
  settlement: "",
  street: "",
};

const actions = {
  setSettlement(context) {
    context.commit("setSettlement");
  },

  setStreet(context) {
    context.commit("setStreet");
  },
};

const mutations = {
  setSettlement(state, data) {
    state.settlement = data;
  },

  setStreet(state, data) {
    state.street = data;
  },
};

const getters = {
  GETSETTLEMENT: (state) => state.settlement,
  GETSTREET: (state) => state.street,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
