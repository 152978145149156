const state = {
  showModal: null,
};

const actions = {
  setShowModal(context, data) {
    context.commit("setShowModal", data);
    
  },
};

const mutations = {
  setShowModal(state, data) {
    state.showModal = data;
    console.log(state.showModal);
  },
};

const getters = {
  GETSHOWMODAL: (state) => state.showModal,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
