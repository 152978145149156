import { AXIOS } from "@/AXIOS/http-common";

const state = {
  organizations: [],
  dangerClasses: [],
  codes: [],
  names: [],
  waste: [],
  organizationWastesList: [],
  organizationWastesListByName: [],
};

const actions = {
  /***
   * Получить список классов опасности
   * @param context
   * @returns {Promise<void>}
   */
  async getDangerClasses(context) {
    return await AXIOS.get("/admin/classifiers/ss01/edit").then((response) => {
      context.commit("setDangerClasses", response.data.dangerClasses);
    });
  },

  /***
   * Получить список кодов
   * @param context
   * @returns {Promise<void>}
   */
  async getCodes(context) {
    return await AXIOS.get("/admin/classifiers/ss01/getcodes").then(
      (response) => {
        context.commit("setCodes", response.data);
      }
    );
  },

  /***
   * Получить список наименований
   * @param context
   * @returns {Promise<void>}
   */
  async getNames(context) {
    return await AXIOS.get("/admin/classifiers/ss01/getnames").then(
      (response) => {
        context.commit("setNames", response.data);
      }
    );
  },

  /***
   * Поиск отходов
   * @param context
   * @param data объект содержащий в себе код, наименование и класс опасности
   * @returns {Promise<void>}
   */
  async searchWaste(context, data) {
    return await AXIOS.get(
      "/admin/classifiers/ss01/search/" +
        data.code +
        "/" +
        data.name +
        "/" +
        data.dangerClass
    ).then((response) => {
      context.commit("setWaste", response.data);
    });
  },
};

const mutations = {
  setDangerClasses(state, data) {
    state.dangerClasses = data;
  },

  setCodes(state, data) {
    state.codes = data;
  },

  setNames(state, data) {
    state.names = data;
  },

  setWaste(state, data) {
    state.waste = data;
  },
};

const getters = {
  GETDANGERCLASSES: (state) => state.dangerClasses,
  GETCODES: (state) => state.codes,
  GETNAMES: (state) => state.names,
  GETWASTE: (state) => state.waste,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
