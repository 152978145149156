import { AXIOS } from "@/AXIOS/http-common";

const state = {
  listAllTechProcesses: [],
  techProcessById: [],
  listAllTechProcessesForForm: [],
};

const urlPrefix = "tech-process";

const actions = {
  async getListAllTechProcesses(context, data) {
    return await AXIOS.get(
      urlPrefix + "/list?page=" + data.page + "&size=" + data.size
    ).then((response) => {
      console.log(response.data);
      context.commit("setListAllTechProcesses", response.data);
    });
  },

  async getListAllTechProcessesForForm(context) {
    return await AXIOS.get(urlPrefix + "/list/form").then((response) => {
      console.log(response.data);
      context.commit("setListAllTechProcessesForForm", response.data);
    });
  },

  async getTechProcessById(context, id) {
    return await AXIOS.get(urlPrefix + "/get?techProcessId=" + id).then(
      (response) => {
        console.log(response.data);
        context.commit("setTechProcessById", response.data);
      }
    );
  },

  async searchTechProcess(context, data) {
    return await AXIOS.get(
      urlPrefix +
        "/search?page=" +
        data.page +
        "&size=" +
        data.size +
        "&input=" +
        data.query
    ).then((response) => {
      console.log(response.data);
      context.commit("setListAllTechProcesses", response.data);
    });
  },

  async techProcessForSelect(context, data) {
    return await AXIOS.get(urlPrefix + "/list/select?unitId=" + data.unitId);
  },

  async createTechProcess(context, data) {
    return await AXIOS.post(urlPrefix + "/create", data);
  },

  async updateTechProcess(context, data) {
    return await AXIOS.put(urlPrefix + "/update", data);
  },

  async deleteProcess(context, id) {
    return await AXIOS.delete(urlPrefix + "/delete?processId=" + id);
  },

  async deleteWasteFromTechProcess(context, id) {
    return await AXIOS.delete(
      urlPrefix + "/delete/waste?techProcessHasWasteId=" + id
    );
  },

  async addWasteToTechProcess(context, data) {
    return await AXIOS.post(urlPrefix + "/add/waste", data);
  },

  async updateWasteToTechProcess(context, data) {
    return await AXIOS.put(urlPrefix + "/update/waste", data);
  },
};

const mutations = {
  setListAllTechProcesses(state, data) {
    state.listAllTechProcesses = data;
  },

  setTechProcessById(state, data) {
    state.techProcessById = data;
  },

  setListAllTechProcessesForForm(state, data) {
    state.listAllTechProcessesForForm = data;
  },
};

const getters = {
  GETLISTTECHPROCESSES: (state) => state.listAllTechProcesses,
  GETTECHPROCESSBYID: (state) => state.techProcessById,
  GETLISTALLTECHPROCESSESFORFORM: (state) => state.listAllTechProcessesForForm,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
