import { AXIOS } from "@/AXIOS/http-common";

const state = {
  organizationUnitInfo: [],
  listUnits: {},
};

const urlPrefix = "user/organization";

const actions = {
  async getOrganizationUnitInfo(context, unitId) {
    return await AXIOS.get(urlPrefix + "/unit/" + unitId + "/get").then(
      (response) => {
        context.commit("setOrganizationUnitInfo", response.data);
      }
    );
  },

  async getListUnits(context, data) {
    return await AXIOS.get(
      urlPrefix +
        "/units/" +
        data.unitType +
        "/list?page=" +
        data.page +
        "&size=" +
        data.size
    ).then((response) => {
      context.commit("setListUnits", response.data);
    });
  },

  async searchUnits(context, data) {
    return await AXIOS.get(
      urlPrefix +
        "/units/" +
        data.unitType +
        "/search?page=" +
        data.page +
        "&size=" +
        data.size +
        "&input=" +
        data.query
    ).then((response) => {
      context.commit("setListUnits", response.data);
    });
  },

  async organizationUnitsForSelect(context, data) {
    return await AXIOS.get(
      urlPrefix + "/units/" + data.unitType + "/select?unitId=" + data.unitId
    );
  },

  async organizationUnitsForSelectDatalimit(context, data) {
    return await AXIOS.get(
      urlPrefix + "/units/" + data.unitType + "/selectDatalimit?unitId=" + data.unitId + "&date=" + data.unitdate
    );
  },
};

const mutations = {
  setOrganizationUnitInfo(state, data) {
    state.organizationUnitInfo = data;
  },

  setListUnits(state, data) {
    state.listUnits = data;
  },
};

const getters = {
  GETORGANIZATIONUNITINFO: (state) => state.organizationUnitInfo,
  GETUNITSLIST: (state) => state.listUnits,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
