import { AXIOS } from "@/AXIOS/http-common";

const state = {
  organizationInfo: [],
  organizationHierarchy: [],
  organizationHierarchyByType: [],
};

const urlPrefix = "user/organization";

const actions = {
  async getOrganizationInfo(context) {
    return await AXIOS.get(urlPrefix + "/get").then((response) => {
      context.commit("setOrganizationInfo", response.data);
    });
  },

  async getOrganizationHierarchy(context, organizationId) {
    return await AXIOS.get(
      urlPrefix + "/hierarchy?organizationId=" + organizationId
    ).then((response) => {
      context.commit("setOrganizationHierarchy", response.data);
    });
  },

  async getOrganizationHierarchyByType(context, data) {
    return await AXIOS.get(urlPrefix + "/hierarchy/" + data.type).then(
      (response) => {
        context.commit("setOrganizationHierarchyByType", response.data);
      }
    );
  },

  async updateOrganizationInfo(context, data) {
    return await AXIOS.put(urlPrefix + "/update", data);
  },

  async createUnit(context, data) {
    return await AXIOS.post(urlPrefix + "/create", data);
  },

  async updateOrganizationUnitInfo(context, data) {
    return await AXIOS.put(urlPrefix + "/unit/update", data);
  },

  async deleteOrganizationUnit(context, unitId) {
    return await AXIOS.delete(urlPrefix + "/unit/" + unitId + "/delete");
  },
};

const mutations = {
  setOrganizationInfo(state, data) {
    state.organizationInfo = data;
  },

  setOrganizationHierarchy(state, data) {
    state.organizationHierarchy = data;
  },

  setOrganizationHierarchyByType(state, data) {
    state.organizationHierarchyByType = data;
  },
};

const getters = {
  GETORGANIZATIONINFO: (state) => state.organizationInfo,
  GETORGANIZATIHIERARCHY: (state) => state.organizationHierarchy,
  GETORGANIZATIHIERARCHYBYTYPE: (state) => state.organizationHierarchyByType,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
