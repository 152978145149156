import { AXIOS } from "@/AXIOS/http-common";

const state = {
  organizations: [],
};

const urlPrefix = "/admin/organization";

const actions = {
  async getAllOrganizations(context, data) {
    return await AXIOS.get(
      urlPrefix + "?page=" + data.page + "&size=" + data.size
    ).then((response) => {
      console.log(response.data);
      context.commit("setOrganizations", response.data);
    });
  },

  async searchOrganizations(context, data) {
    return await AXIOS.get(
      urlPrefix +
        "/search?searchedSegment=" +
        data.query +
        "&page=" +
        data.page +
        "&size=" +
        data.size
    ).then((response) => {
      console.log(response.data);
      context.commit("setOrganizations", response.data);
    });
  },

  async addNewOrganization(context, data) {
    return await AXIOS.post(urlPrefix + "/create", data);
  },

  async editOrganization(context, data) {
    console.log(data);
    return await AXIOS.put(urlPrefix + "/edit", data);
  },

  async deleteOrganization(context, organizationId) {
    return await AXIOS.delete(
      urlPrefix + "/delete?organizationId=" + organizationId
    );
  },
};

const mutations = {
  setOrganizations(state, data) {
    state.organizations = data;
  },
};

const getters = {
  GETORGANIZATIONS: (state) => state.organizations,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
