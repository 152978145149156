import { AXIOS } from "@/AXIOS/http-common";

const state = {
  listTypes: [],
};

const urlPrefix = "classifiers/subClassifiers/areas";

const actions = {
  async getListAllSettlementTypes(context, data) {
    return await AXIOS.get(
      urlPrefix +
        "/" +
        data.areaId +
        "/districts/" +
        data.districtId +
        "/types?page=" +
        data.page +
        "&size=" +
        data.size
    ).then((response) => {
      console.log(response);
      context.commit("setListTypes", response.data);
    });
  },
};

const mutations = {
  setListTypes(state, data) {
    state.listTypes = data;
  },
};

const getters = {
  GETLISTTYPES: (state) => state.listTypes,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
