import { AXIOS } from "@/AXIOS/http-common";

const state = {
  listOrganizationWastes: [],
  organizationWaste: {},
  organizationWastesList: [],
};

const urlPrefix = "organization-waste";

const actions = {
  async getOrganizationWastesListByOrganizationId(context) {
    return await AXIOS.get(urlPrefix + "/get/organization/wastes").then(
      (response) => {
        context.commit("setListAllOrganizationWastes", response.data);
      }
    );
  },
  async getOrganizationWastesListByOrganizationIdAndDate(context, data) {
    return await AXIOS.get(urlPrefix + "/get/organization/wastesdate?date=" + data).then(
      (response) => {
        context.commit("setListAllOrganizationWastes", response.data);
      }
    );
  },

  async getDataWasteTechProcessOrg(context, data) {
    console.log(context);
    return await AXIOS.post(
      urlPrefix + "/get/waste/org/val", data
    );
  },
  async setWasteDisposalHistory(context, data) {
    console.log(context);
    return await AXIOS.post(
      urlPrefix + "/set/waste/history", data
    );
  },

  async getListAllOrganizationWastes(context, data) {
    return await AXIOS.get(
      urlPrefix + "/list?page=" + data.page + "&size=" + data.size
    ).then((response) => {
      context.commit("setListAllOrganizationWastes", response.data);
    });
  },

  async searchOrganizationWastes(context, data) {
    return await AXIOS.get(
      urlPrefix +
        "/search?input=" +
        data.query +
        "&page=" +
        data.page +
        "&size=" +
        data.size
    ).then((response) => {
      context.commit("setListAllOrganizationWastes", response.data);
    });
  },

  async getOrganizationWaste(context, organizationWasteId) {
    return await AXIOS.get(
      urlPrefix + "/get?organizationWasteId=" + organizationWasteId
    ).then((response) => {
      context.commit("setOrganizationWaste", response.data);
    });
  },

  async getOrganizationWastesList(context, id) {
    return await AXIOS.get(
      urlPrefix + "/get/organization/tech-process/wastes?techProcessId=" + id
    ).then((response) => {
      context.commit("setOrganizationWastesList", response.data);
    });
  },

  async createOrganizationWaste(context, data) {
    return await AXIOS.post(urlPrefix + "/create", data);
  },

  async updateOrganizationWaste(context, data) {
    return await AXIOS.put(urlPrefix + "/update", data);
  },

  async deleteOrganizationWaste(context, id) {
    return await AXIOS.delete(urlPrefix + "/delete?organizationWasteId=" + id);
  },
};

const mutations = {
  setListAllOrganizationWastes(state, data) {
    state.listOrganizationWastes = data;
  },

  setOrganizationWaste(state, data) {
    state.organizationWaste = data;
  },

  setOrganizationWastesList(state, data) {
    state.organizationWastesList = data;
  },
};

const getters = {
  GETLISTWASTEPRODUCTION: (state) => state.listOrganizationWastes,
  GETORGANIZATIONWASTE: (state) => state.organizationWaste,
  GETORGANIZATIONWASTELIST: (state) => state.organizationWastesList,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
