import { AXIOS } from "@/AXIOS/http-common";

const state = {
  listCities: [],
  listCitiesNames: [],
  selectedCity: {},
};

const urlPrefix = "classifiers/subClassifiers/areas";

const actions = {
  setSelectedCity(context, data) {
    context.commit("setSelectedCity", data);
  },

  async getListAllCitiesByAreaIdAndDistrictId(context, data) {
    return await AXIOS.get(
      urlPrefix +
        "/" +
        data.areaId +
        "/districts/" +
        data.districtId +
        "/types/" +
        data.typeId +
        "/cities/list?page=" +
        data.page +
        "&size=" +
        data.size
    ).then((response) => {
      console.log(response);
      context.commit("setListCities", response.data);
    });
  },

  async getCitiesNames(context, data) {
    return await AXIOS.get(
      urlPrefix +
        "/" +
        data.areaId +
        "/districts/" +
        data.districtId +
        "/cities/search"
    ).then((response) => {
      console.log(response);
      context.commit("setListCitiesNames", response.data);
    });
  },

  async getCitiesNamesForUser(context, data) {
    return await AXIOS.get(
      urlPrefix +
        "/" +
        data.areaId +
        "/districts/" +
        data.districtId +
        "/cities/form/search?searchValue=" +
        data.query
    ).then((response) => {
      console.log(response);
      context.commit("setListCitiesNames", response.data);
    });
  },

  async createCity(context, data) {
    return await AXIOS.post(urlPrefix + "/city/create", data);
  },

  async updateCity(context, data) {
    return await AXIOS.put(urlPrefix + "/city/update", data);
  },

  async deleteCity(context, data) {
    return await AXIOS.delete(urlPrefix + "/city/delete", {
      data: data,
    });
  },
};

const mutations = {
  setListCities(state, data) {
    state.listCities = data;
  },

  setListCitiesNames(state, data) {
    state.listCitiesNames = data;
  },

  setSelectedCity(state, data) {
    state.selectedCity = data;
  },
};

const getters = {
  GETLISTCITIES: (state) => state.listCities,
  GETLISTCITIESNAMES: (state) => state.listCitiesNames,
  GETSELECTEDCITY: (state) => state.selectedCity,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
