import { AXIOS } from "@/AXIOS/http-common";

const state = {
  licenseList: [],
};

const urlPrefix = "license";

const actions = {
  async getListAllLicenses(context) {
    return await AXIOS.get(urlPrefix + "/list").then((response) => {
      context.commit("setListAllLicenses", response.data);
    });
  },

  async createLicense(context, data) {
    return await AXIOS.post(urlPrefix + "/create", data);
  },

  async updateLicense(context, data) {
    return await AXIOS.put(urlPrefix + "/update", data);
  },

  async deleteLicense(context, id) {
    return await AXIOS.delete(urlPrefix + "/delete?licenseId=" + id);
  },

  async deleteWasteFromLicense(context, data) {
    return await AXIOS.delete(
      urlPrefix + "/" + data.licenceId + "/delete/waste/" + data.wasteId
    );
  },

  async checkLicenseValidityDate() {
    return await AXIOS.get(urlPrefix + "/check/validity/date");
  },

  async addWasteToLicense(context, data) {
    return await AXIOS.post(
      urlPrefix +
        "/add/waste?wastesId=" +
        data.wastesId +
        "&licenseId=" +
        data.licenseId
    );
  },
};

const mutations = {
  setListAllLicenses(state, data) {
    state.licenseList = data;
  },
};

const getters = {
  GETLICENSELIST: (state) => state.licenseList,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
