import { AXIOS } from "@/AXIOS/http-common";

const state = {
  registries: [],
};

const actions = {
  async getAllRegistries(context, data) {
    return await AXIOS.get(
      "classifiers/registry/list?page=" + data.page + "&size=" + data.size
    ).then((response) => {
      console.log(response);
      context.commit("setRegistries", response.data);
    });
  },

  async searchRegistriesBySS01Waste(context, data) {
    console.log(data);
    return await AXIOS.get(
      "/admin/registries/use_registry/search/" + data.code + "/" + data.name
    ).then((response) => {
      console.log(response);
      context.commit("setRegistries", response.data.page.content);
    });
  },
};

const mutations = {
  setRegistries(state, data) {
    state.registries = data;
  },
};

const getters = {
  GETREGISTRYLIST: (state) => state.registries,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
