import Vue from "vue";
import Vuex from "vuex";
import router from "../router/index";
import organizations from "@/store/modules/admin/organizations";
import users from "@/store/modules/users";
import usersAdmin from "@/store/modules/admin/users";
import classifiers from "@/store/modules/admin/classifiers";
import PSS24 from "@/store/modules/admin/subClassifiers/PSS24";
import PSS44 from "@/store/modules/admin/subClassifiers/PSS44";
import registry from "@/store/modules/admin/registry";
import selectAddress from "@/store/modules/selectAddress";
import ss01dictionary from "@/store/modules/admin/classifiers/ss01dictionary";
import genericClassifiers from "@/store/modules/admin/classifiers/genericClassifiers";
import areas from "@/store/modules/admin/subClassifiers/areas";
import adminDistricts from "@/store/modules/admin/subClassifiers/districts";
import settlementTypes from "@/store/modules/admin/subClassifiers/settlementTypes";
import cities from "@/store/modules/admin/subClassifiers/cities";
import egr from "@/store/modules/admin/egr";
import userOrganization from "@/store/modules/ecologist/userOrganization";
import modals from "@/store/modules/modals";
import license from "@/store/modules/ecologist/license";
import licenseActionSubClassifier from "@/store/modules/ecologist/licenseActionSubClassifier";
import lots from "@/store/modules/ecologist/lots";
import permission from "@/store/modules/ecologist/permission";
import organizationUnits from "@/store/modules/ecologist/organizationUnits";
import techProcessesTable from "@/store/modules/ecologist/techProcessesTable";
import contractors from "@/store/modules/ecologist/classifiers/contractors";
import administrativeDocument from "@/store/modules/ecologist/classifiers/administrativeDocument";
import responsiblePersons from "@/store/modules/ecologist/classifiers/responsiblePersons";
import wasteProduction from "@/store/modules/ecologist/wasteProduction";
import dataEntry from "@/store/modules/ecologist/dataEntry";
import reports from "@/store/modules/ecologist/reports";
import wasteHistory from "@/store/modules/ecologist/wasteHistory";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    routes: router.options.routes,
  },
  actions: {},
  mutations: {},
  getters: {
    GETROUTES: (state) => state.routes,
  },
  modules: {
    lots,
    organizations,
    users,
    classifiers,
    PSS24,
    PSS44,
    registry,
    selectAddress,
    ss01dictionary,
    genericClassifiers,
    usersAdmin,
    areas,
    adminDistricts,
    settlementTypes,
    cities,
    egr,
    userOrganization,
    modals,
    license,
    licenseActionSubClassifier,
    permission,
    organizationUnits,
    techProcessesTable,
    contractors,
    administrativeDocument,
    responsiblePersons,
    wasteProduction,
    dataEntry,
    reports,
    wasteHistory,
  },
});
