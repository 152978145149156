import { AXIOS } from "@/AXIOS/http-common";

const state = {
  listAllAreas: [],
};

const urlPrefix = "classifiers/subClassifiers/areas";

const actions = {
  async getListAllDistrictsByAreaId(context, data) {
    return await AXIOS.get(
      urlPrefix +
        "/" +
        data.areaId +
        "/districts/list?page=" +
        data.page +
        "&size=" +
        data.size
    ).then((response) => {
      console.log(response);
      context.commit("setListAllAreas", response.data);
    });
  },

  async getListAllDistrictsByAreaIdAndDistrictId(context, data) {
    return await AXIOS.get(
      urlPrefix +
        "/" +
        data.areaId +
        "/districts/" +
        data.districtId +
        "/cities/list?page=" +
        data.page +
        "&size=" +
        data.size
    ).then((response) => {
      console.log(response);
      context.commit("setListAllAreas", response.data);
    });
  },
};

const mutations = {
  setListAllAreas(state, data) {
    state.listAllAreas = data;
  },
};

const getters = {};

export default {
  state,
  actions,
  mutations,
  getters,
};
