import { AXIOS } from "@/AXIOS/http-common";

const state = {
  listAdministrativeDocument: [],
  listDocumentNumbers: [],
};

const urlPrefix = "administrativeDocument";

const actions = {
  async getAllAdministrativeDocument(context, data) {
    return await AXIOS.get(
      urlPrefix + "/list?page=" + data.page + "&size=" + data.size
    ).then((response) => {
      console.log(response.data);
      context.commit("setListAdministrativeDocument", response.data);
    });
  },

  async searchAdministrativeDocument(context, data) {
    return await AXIOS.get(
      urlPrefix +
        "/search?searchedSegment=" +
        data.query +
        "&page=" +
        data.page +
        "&size=" +
        data.size
    ).then((response) => {
      console.log(response.data);
      context.commit("setListAdministrativeDocument", response.data);
    });
  },

  async getDocsNumbersSearch(context, data) {
    return await AXIOS.get(
      urlPrefix + "/search/numbers?numberSegment=" + data.query
    ).then((response) => {
      console.log(response.data);
      context.commit("setListDocumentNumbers", response.data);
    });
  },

  async createAdministrativeDocument(context, data) {
    return await AXIOS.post(urlPrefix + "/create", data);
  },

  async updateAdministrativeDocument(context, data) {
    return await AXIOS.post(urlPrefix + "/update", data);
  },

  async deleteAdministrativeDocument(context, id) {
    return await AXIOS.delete(urlPrefix + "/delete?documentId=" + id);
  },

  async downloadFile(context, filePath) {
    let fileName = filePath.slice(filePath.lastIndexOf("\\") + 1);
    AXIOS({
      url: urlPrefix + "/download?filePath=" + encodeURI(filePath), //your url
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
    // return await AXIOS.get(
    //   urlPrefix + "/download?filePath=" + encodeURI(filePath)
    // ).then((response) => {
    //   const url = window.URL.createObjectURL(new Blob([response.data]));
    //   const link = document.createElement("a");
    //   link.href = url;
    //   link.setAttribute("download", fileName); //or any other extension
    //   document.body.appendChild(link);
    //   link.click();
    // });
  },
};

const mutations = {
  setListAdministrativeDocument(state, data) {
    state.listAdministrativeDocument = data;
  },

  setListDocumentNumbers(state, data) {
    state.listDocumentNumbers = data;
  },
};

const getters = {
  GETLISTADMINISTRATIVEDOCUMENT: (state) => state.listAdministrativeDocument,
  GETLISTDOCUMENTNUMBERS: (state) => state.listDocumentNumbers,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
