import { AXIOS } from "@/AXIOS/http-common";

const state = {
  permissionList: [],
};

const urlPrefix = "permission";

const actions = {
  async getListAllPermissions(context) {
    return await AXIOS.get(urlPrefix + "/list").then((response) => {
      context.commit("setListAllPermissions", response.data);
    });
  },

  async createPermission(context, data) {
    return await AXIOS.post(urlPrefix + "/create", data);
  },

  async updatePermission(context, data) {
    return await AXIOS.put(urlPrefix + "/update", data);
  },

  async deletePermission(context, id) {
    return await AXIOS.delete(urlPrefix + "/delete?licenseId=" + id);
  },

  async checkPermissionValidityDate() {
    return await AXIOS.get(urlPrefix + "/check/validity/date");
  },
};

const mutations = {
  setListAllPermissions(state, data) {
    state.permissionList = data;
  },
};

const getters = {
  GETPERMISSIONLIST: (state) => state.permissionList,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
