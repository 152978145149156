import { AXIOS } from "@/AXIOS/http-common";

const state = {
  listAllContractors: [],
  listContractors: [],
  listContractorsByEgr: [],
};

const urlPrefix = "contractor";

const actions = {
  async getAllContractors(context, data) {
    return await AXIOS.get(
      urlPrefix + "/list?page=" + data.page + "&size=" + data.size
    ).then((response) => {
      //console.log(response.data);
      context.commit("setListAllContractors", response.data);
    });
  },

  async searchContractorsByName(context, data) {
    return await AXIOS.get(
      urlPrefix +
        "/search/name?searchedSegment=" +
        data.query +
        "&page=" +
        data.page +
        "&size=" +
        data.size
    ).then((response) => {
      console.log(response.data);
      context.commit("setListAllContractors", response.data);
    });
  },

  async searchContractorsByEGR(context, data) {
    return await AXIOS.get(
      urlPrefix +
        "/search/egr?searchedSegment=" +
        data.query +
        "&page=" +
        data.page +
        "&size=" +
        data.size
    ).then((response) => {
      console.log(response.data);
      context.commit("setListContractorsByEgr", response.data);
    });
  },

  async getListContractors(context) {
    return await AXIOS.get(urlPrefix + "/list/for/form").then((response) => {
      console.log(response.data);
      context.commit("setListContractors", response.data);
    });
  },

  async createContractor(context, data) {
    return await AXIOS.post(urlPrefix + "/create", data);
  },

  async updateContractor(context, data) {
    return await AXIOS.put(urlPrefix + "/update", data);
  },

  async deleteContractor(context, id) {
    return await AXIOS.delete(urlPrefix + "/delete?contractorId=" + id);
  },
};

const mutations = {
  setListAllContractors(state, data) {
    state.listAllContractors = data;
  },

  setListContractors(state, data) {
    state.listContractors = data;
  },

  setListContractorsByEgr(state, data) {
    state.listContractorsByEgr = data;
  },
};

const getters = {
  GETLISTCONTRACTORS: (state) => state.listAllContractors,
  GETLISTCONTRACTORSSELECT: (state) => state.listContractors,
  GETLISTCONTRACTORSBYEGR: (state) => state.listContractorsByEgr,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
