import { AXIOS } from "@/AXIOS/http-common";

const state = {
  listEconomyActivitySubClassifier: [],
  listEconomyActivitySubClassifierByParentId: [],
  economySubClassifier: [],
};

const urlPrefix = "classifiers/subClassifiers/economy";

const actions = {
  async getListEconomyActivitySubClassifier(context) {
    return await AXIOS.get(urlPrefix + "/list").then((response) => {
      console.log(response);
      context.commit("setListEconomyActivitySubClassifier", response.data);
    });
  },

  async getListEconomyActivitySubClassifierByParentId(context, parentId) {
    return await AXIOS.get(urlPrefix + "/" + parentId + "/list").then(
      (response) => {
        console.log(response);
        context.commit(
          "setListEconomyActivitySubClassifierByParentId",
          response.data
        );
      }
    );
  },

  async getEconomySubClassifier(context) {
    return await AXIOS.get(urlPrefix + "/get").then((response) => {
      console.log(response);
      context.commit("setEconomySubClassifier", response.data);
    });
  },
};

const mutations = {
  setListEconomyActivitySubClassifier(state, data) {
    state.listEconomyActivitySubClassifier = data;
  },

  setListEconomyActivitySubClassifierByParentId(state, data) {
    state.listEconomyActivitySubClassifierByParentId = data;
  },

  setEconomySubClassifier(state, data) {
    state.economySubClassifier = data;
  },
};

const getters = {
  GETLISTECONOMYACTIVITY: (state) => state.listEconomyActivitySubClassifier,
  GETECONOMYSUBCLASSIFIER: (state) => state.economySubClassifier,
  GETLISTECONOMYACTIVITYBYPARENTID: (state) =>
    state.listEconomyActivitySubClassifierByParentId,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
