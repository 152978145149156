import { AXIOS } from "@/AXIOS/http-common";

const state = {
  listDistricts: [],
  listDistrictNames: [],
};

const urlPrefix = "classifiers/subClassifiers/areas";

const actions = {
  async listAllDistrictsByAreaId(context, data) {
    return await AXIOS.get(
      urlPrefix +
        "/" +
        data.areaId +
        "/districts/list?page=" +
        data.page +
        "&size=" +
        data.size
    ).then((response) => {
      console.log(response);
      context.commit("setListDistricts", response.data);
    });
  },

  async getDistrictNames(context, data) {
    return await AXIOS.get(
      urlPrefix +
        "/" +
        data.areaId +
        "/districts/search?searchValue=" +
        data.query
    ).then((response) => {
      console.log(response);
      context.commit("setListDistrictNames", response.data);
    });
  },

  async createDistrict(context, data) {
    return await AXIOS.post(urlPrefix + "/district/create", data);
  },

  async updateDistrict(context, data) {
    return await AXIOS.put(urlPrefix + "/district/update", data);
  },

  async deleteDistrict(context, data) {
    return await AXIOS.delete(urlPrefix + "/district/delete", {
      data: data,
    });
  },
};

const mutations = {
  setListDistricts(state, data) {
    state.listDistricts = data;
  },

  setListDistrictNames(state, data) {
    state.listDistrictNames = data;
  },
};

const getters = {
  GETLISTDISTRICS: (state) => state.listDistricts,
  GETLISTDISTRICNAMES: (state) => state.listDistrictNames,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
