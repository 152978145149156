import axios from "axios";
import nprogress from "nprogress";
import Cookies from "js-cookie";

let config = {
  //baseURL: `http://localhost:8080/api`,
  baseURL: `https://waste.eco3.by/api`,
  timeout: 60000,
};

if (Cookies.get("eco-token") !== undefined) {
  config.headers = { Authorization: `Bearer ${Cookies.get("eco-token")}` };
}

export const AXIOS = axios.create(config);

AXIOS.interceptors.request.use((config) => {
  nprogress.start();
  return config;
});

AXIOS.interceptors.response.use((response) => {
  nprogress.done();
  return response;
});
