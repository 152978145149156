import { AXIOS } from "@/AXIOS/http-common";

const state = {
  listSubClassifiers: [],
  subClassifierData: [],
};

const urlPrefix = "classifiers/subClassifiers";

const actions = {
  async getListAllSubClassifiers(context) {
    return await AXIOS.get(urlPrefix + "/").then((response) => {
      console.log(response);
      context.commit("setListSubClassifiers", response.data);
    });
  },

  async getAllDataSubClassifier(context, data) {
    return await AXIOS.get(
      urlPrefix +
        "/list?subClassifierId=" +
        data.id +
        "&size=" +
        data.size +
        "&page=" +
        data.page
    ).then((response) => {
      console.log(response);
      context.commit("setSubClassifierData", response.data);
    });
  },

  async getAllDataSubClassifierForSelect(context, data) {
    return await AXIOS.get(
      urlPrefix +
        "/list?subClassifierId=" +
        data.id +
        "&size=" +
        data.size +
        "&page=" +
        data.page
    );
  },

  async createSubClassifier(context, data) {
    return await AXIOS.post(
      urlPrefix + "/create?subClassifierId=" + data.subClassifierId,
      data.subClassifier
    );
  },

  async updateSubClassifier(context, data) {
    return await AXIOS.put(
      urlPrefix + "/update?subClassifierId=" + data.subClassifierId,
      data.subClassifier
    );
  },

  async deleteSubClassifier(context, data) {
    return await AXIOS.delete(
      urlPrefix +
        "/delete?subClassifierId=" +
        data.subClassifierId +
        "&id=" +
        data.itemId
    );
  },
};

const mutations = {
  setListSubClassifiers(state, data) {
    state.listSubClassifiers = data;
  },

  setSubClassifierData(state, data) {
    state.subClassifierData = data;
  },
};

const getters = {
  GETLISTSUBCLASSIFIERS: (state) => state.listSubClassifiers,
  GETSUBCLASSIFIERDATA: (state) => state.subClassifierData,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
