import { AXIOS } from "@/AXIOS/http-common";

const state = {
  roleList: [],
  authUser: null,
  licenseMessage: [],
  permissionMessage: [],
  storeMessage:[],
  regMessage:[],
  check1LotsBid:[],
  check2LotsBid:[],
  
};

const actions = {
  async auth(context, data) {
    return await AXIOS.get(
      "/user/auth?username=" + data.username + "&password=" + data.password
    );
  },

  async getAllUserRoles(context) {
    return await AXIOS.get("/user/role/list").then((response) => {
      console.log(response);
      context.commit("setRoleList", response.data);
    });
  },

  async getUserInfoByToken(context, token) {
    return await AXIOS.get("/user/getFromToken?token=" + token).then(
      (response) => {
        console.log(response);
        context.commit("setAuthUser", response.data);
      }
    );
  },

  setLicenseMessage(context, data) {
    console.log("TEST", data);
    context.commit("setLicenseMessage", data);
  },

  setPermissionMessage(context, data) {
    console.log("TEST", data);
    context.commit("setPermissionMessage", data);
  },
  setStoreMessage(context, data) {
    console.log("TEST", data);
    context.commit("setStoreMessage", data);
  },

  setRegMessage(context, data) {
    console.log("TEST", data);
    context.commit("setRegMessage", data);
  },

  setCheck1LotsBid(context, data) {
    context.commit("setCheck1LotsBid", data);
  },

  setCheck2LotsBid(context, data) {
    context.commit("setCheck2LotsBid", data);
  },
};

const mutations = {
  setRoleList(state, data) {
    state.roleList = data;
  },

  setAuthUser(state, data) {
    state.authUser = data;
  },

  setLicenseMessage(state, data) {
    state.licenseMessage = data;
  },

  setPermissionMessage(state, data) {
    state.permissionMessage = data;
  },
  setStoreMessage(state, data) {
    state.storeMessage = data;
  },
  setRegMessage(state, data) {
    state.regMessage = data;
  },

  setCheck1LotsBid(state, data) {
    state.check1LotsBid = data;
  },

  setCheck2LotsBid(state, data) {
    state.check2LotsBid = data;
  },
};

const getters = {
  GETROLELIST: (state) => state.roleList,
  GETAUTHUSER: (state) => state.authUser,
  GETLICENSEMESSAGE: (state) => state.licenseMessage,
  GETPERMISSIONMESSAGE: (state) => state.permissionMessage,
  GETSTOREMESSAGE: (state) => state.storeMessage,
  GETREGMESSAGE: (state) => state.regMessage,
  GETCHECK1LOTSBID: (state) => state.check1LotsBid,
  GETCHECK2LOTSBID: (state) => state.check2LotsBid,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
