import { AXIOS } from "@/AXIOS/http-common";

const state = {
  listLicenseAction: [],
};

const urlPrefix = "classifiers/subClassifiers/licenseAction";

const actions = {
  async getListLicenseActionData(context) {
    return await AXIOS.get(urlPrefix + "/list").then((response) => {
      context.commit("setListLicenseActionData", response.data);
    });
  },
};

const mutations = {
  setListLicenseActionData(state, data) {
    state.listLicenseAction = data;
  },
};

const getters = {
  GETLISTLICENSEACTION: (state) => state.listLicenseAction,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
