import { AXIOS } from "@/AXIOS/http-common";

const state = {
  egrList: [],
  egrNamesList: [],
  egrIdsList: [],
};

const actions = {
  async getListEgrObjects(context, data) {
    return await AXIOS.get(
      "/classifiers/egr/list?page=" + data.page + "&size=" + data.size
    ).then((response) => {
      console.log(response);
      context.commit("setEgrList", response.data);
    });
  },

  async getEgrObject(context, id) {
    return await AXIOS.get("/classifiers/egr/get?egrId=" + id).then(
      (response) => {
        console.log(response);
        let data = {
          content: [],
        };

        data.content.push(response.data);
        context.commit("setEgrList", data);
      }
    );
  },

  async getEgrNames(context, data) {
    return await AXIOS.get(
      "/classifiers/egr/getNames?input=" + data.query
    ).then((response) => {
      console.log(response);
      context.commit("setEgrNamesList", response.data);
    });
  },

  async getEgrIds(context, data) {
    return await AXIOS.get(
      "/classifiers/egr/getEgrIDs?input=" + data.query
    ).then((response) => {
      console.log(response);
      context.commit("setEgrIdsList", response.data);
    });
  },
};

const mutations = {
  setEgrList(state, data) {
    state.egrList = data;
  },

  setEgrNamesList(state, data) {
    state.egrNamesList = data;
  },

  setEgrIdsList(state, data) {
    state.egrIdsList = data;
  },
};

const getters = {
  GETEGRLIST: (state) => state.egrList,
  GETEGRNAMESLIST: (state) => state.egrNamesList,
  GETEGRIDSLIST: (state) => state.egrIdsList,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
