import Vue from "vue";

let notification = Vue.mixin({
  methods: {
    notification(title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
      });
    },

    confirm(title, message, type, confirmButtonText, cancelButtonText) {
      return this.$confirm(message, title, {
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        type: type,
      });
    },

    async message(message, type, showClose) {
      await this.$message({
        showClose: showClose,
        type: type,
        message: message,
      });
    },
  },
});

export default notification;
