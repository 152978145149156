import { AXIOS } from "@/AXIOS/http-common";


const urlPrefix = "Lots";
const actions = {
async addLots(context, data) {
    return await AXIOS.post(urlPrefix + "/create?type="+data.type, data.lots);
  },
async addBid(context, data) {
    return await AXIOS.post(urlPrefix + "/createBid", data);
  },
async getMainLots() {
    return await AXIOS.get(urlPrefix + "/getMain");
  },
async getMainBid() {
    return await AXIOS.get(urlPrefix + "/getMainBid");
  },
  async getMainDoneLots() {
    return await AXIOS.get(urlPrefix + "/getMainDone");
  },
async getMainDoneBid() {
    return await AXIOS.get(urlPrefix + "/getMainDoneBid");
  },
  
async searchLotsString(context, data)
  {
    return await AXIOS.post(urlPrefix + "/searchLotsString", data);
  },
async searchLots(context, data)
  {
    return await AXIOS.post(urlPrefix + "/get", data);
  },
  async searchBid(context, data)
  {
    return await AXIOS.post(urlPrefix + "/searchBid", data);
  },
async LotBidDon(context, data)
  {
    return await AXIOS.post(urlPrefix + "/doneBind", data);
  },
async LotBidCansel(context, data)
  {
    return await AXIOS.post(urlPrefix + "/deletebid", data);
  },
async LotDon(context, data)
  {
    return await AXIOS.post(urlPrefix + "/doneLots", data);
  },
  async getInfo(context, data)
  {
    return await AXIOS.get(urlPrefix + "/getInfo?userId=" + data);
  },
  
}


export default {
    actions,
  };